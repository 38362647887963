import TrackingPixel from "src/components/ads/TrackingPixel";
import theme from "src/components/themes/DefaultTheme";
import styled, { css } from "src/lib/styles/css";

//#region [Props]
type ProgramControlsSponsorProps = {
    isSticky?: boolean;
    className?: string;
};
//#endregion

const clickDestination = "https://www.coca-cola.com/no/no/offerings/fanta-appelsin?utm_source=Filmweb&utm_medium=Sponsorship&utm_campaign=Filmweb_Fanta_Sponsorship";

//#region [Component]
export default function ProgramControlsSponsor({ isSticky, className }: ProgramControlsSponsorProps) {
    return <SSponsor href={clickDestination} target="_blank" className={className} $isSticky={isSticky}>
        <TrackingPixel targetId="SponsorTrackingPixel" />
    </SSponsor>;
}
//#endregion

//#region [Styles]
type SSponsorProps = {
    $isSticky?: boolean;
}

const SSponsor = styled.a<SSponsorProps>`
    display: block;
    background-repeat: no-repeat;
    background-size: contain;

    ${ p => p.$isSticky ? css`
        background-image: url("/fantasponsorat2024/FantaStickyHeaderProgram250x90.png");
        aspect-ratio: 250 / 90;
    `: css`
        background-image: url("/fantasponsorat2024/FantaMobilKinoprogrammetDefault750x350.png");
        aspect-ratio: 750 / 350;
    `}

    @media ${theme.mq.desktop} {
        background-image: url("/fantasponsorat2024/FantaStickyHeaderProgram250x90.png");
        aspect-ratio: 250 / 90;
    }
`;
//#endregion