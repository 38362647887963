"use client"
import DateSelectorButton from "src/components/dateselector/DateSelectorButton";
import FilterSelectorButton from "src/components/filterselector/FilterSelectorButton";
import LocationSelectorButton from "src/components/locationselector/LocationSelectorButton";
import theme from "src/components/themes/DefaultTheme";
import { format, isToday } from "date-fns";
import locale from "date-fns/locale/nb";
import { useLocationContext } from "src/lib/contexts/LocationContext";
import { useProgramDatesContext } from "src/lib/contexts/ProgramDatesWrapper";
import styled, { css } from 'src/lib/styles/css';
import { useInView } from 'react-intersection-observer';
import ProgramControlsSponsor from "src/components/takoversponsor/ProgramControlsSponsor";

//#region [Props]
type ProgramControlsProps = {
    hasSponsor?: boolean;
};
//#endregion

//#region [Component]
export default function ProgramControls({ hasSponsor }: ProgramControlsProps) {
    const locationContext = useLocationContext();
    const selectedDateContext = useProgramDatesContext();
    // https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/
    const { ref, entry } = useInView({
        /* Optional options */
        threshold: 1,
        delay: 200
    });

    let headerText = "Kinoprogram";
    if (locationContext.location) {
        headerText += ` for ${locationContext.location[0].toUpperCase()}${locationContext.location.substring(1)}`;
    }

    if (selectedDateContext.selectedDate) {
        if (isToday(selectedDateContext.selectedDate)) {
            headerText += " i dag";
        } else {
            headerText += ` ${format(selectedDateContext.selectedDate, "iiii d. MMM", { locale })}`;
        }
    }

    const isSticky = (entry?.intersectionRatio ?? 1) < 1;

    return <SControlsContainer $isSticky={(entry?.intersectionRatio ?? 1) < 1} ref={ref}>
        {hasSponsor && <SProgramControlsSponsor isSticky={isSticky} />}
        <STitle $isSticky={isSticky} $hasSponsor={hasSponsor}>{headerText}</STitle>
        <SDateSelectorButton useStaticText />
        <SLocationSelectorButton useStaticText />
        <SFilterSelectorButton />
    </SControlsContainer>;
}
//#endregion

//#region [Styles]
type IsStickyProps = {
    $isSticky: boolean;
    $hasSponsor?: boolean;
}

const SControlsContainer = styled.div<IsStickyProps>`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 1px 9px 0 9px;

	justify-content: space-between;
	position: sticky;

	top: -1px;
	z-index: 10;

	margin-bottom: 25px;
	background: var(--backgroundcolor);

	@media ${theme.mq.desktop} {
		//@media  (orientation: landscape) {
		justify-content: flex-start;
		top: -1px;
		flex-wrap: nowrap;
		gap: 34px;
		height: 100px;
	}

	${props => props.$isSticky ? css`
		padding-bottom: 9px;
		margin-bottom: 0;
		@media ${theme.mq.desktop} {
			//@media  (orientation: landscape) {
			padding-bottom: 0;
			height: ${props.$hasSponsor ? 60 : 90}px;
		}
	` : ""}
`;

const sponsorSize = 150;

const SProgramControlsSponsor = styled(ProgramControlsSponsor)`

    ${p => p.isSticky ? css`
        order: 1;
        flex: 0 0 ${sponsorSize}px;
        margin-bottom: 5px;
    `: css`
        order: 0;
        flex: 0 0 100%;
    `}

    @media ${theme.mq.desktop} {
        order: 7;
        margin-left: auto;
        flex: 0 0 250px;
    }
`;


const STitle = styled.h1<IsStickyProps>`
	text-align: left;
	padding: 0;
	flex: 0 0 100%;
    order: 1;

	font-size: 24px;
	font-weight: bold;
	color: var(--textcolor);

	@media ${theme.mq.desktop} {
		//@media #{settings.$desktop} {//@media (orientation: landscape) {
		flex: 0 0 auto;
	}

	${props => props.$isSticky ? css`
		@media ${theme.mq.desktop} {
			margin-bottom: 0;
		}

		@media ${theme.mq.mobile} {

			font-size: 16px;
			//opacity: 0;
			order: -1;
			margin-bottom: 0;

            ${props.$hasSponsor ? css`
                flex: 1 1 calc(100% - ${sponsorSize}px);
                `: css`
                flex: 1 1 calc(100% - 60px);
            ` }
		}
	` : ""}
`;

const SDateSelectorButton = styled(DateSelectorButton)`
    order: 3;
`;

const SLocationSelectorButton = styled(LocationSelectorButton)`
    order: 4;
`;

const SFilterSelectorButton = styled(FilterSelectorButton)`
    order: 6;
`;

//#endregion