import Button from 'src/components/button/Button';
import CalendarSvg from 'src/components/svg/CalendarSvg';
import theme from 'src/components/themes/DefaultTheme';
import { format, isSameDay } from "date-fns";
import locale from "date-fns/locale/nb";
import { useProgramDatesContext } from 'src/lib/contexts/ProgramDatesWrapper';
import styled from 'src/lib/styles/css';

//#region [Props]
type NoShowsProps = {
	className?: string;
};
//#endregion

//#region [Component]
export default function NoShows({ className }: NoShowsProps) {
	const datesContext = useProgramDatesContext();

	let content: React.ReactNode;

	let showNextShowDate = false;

	if ((datesContext?.availableDates?.length ?? 0) > 0) {
		showNextShowDate = true;
		if (datesContext.selectedDate && !datesContext.availableDates!.some(d => isSameDay(d, datesContext.selectedDate!))) {
			content = <>Ingen forestillinger for {format(datesContext.selectedDate, 'd. MMMM', { locale })}</>;

		}
	} else {
		content = <>Ingen forestillinger tilgjengelig for valgt kinosted</>;
	}

	return <SNoShows className={className}>
		<SText>
			{content}
			{showNextShowDate && <div>Neste dag med film på programmet er <SDateSelectorButton
				text={format(new Date(datesContext.availableDates![0]), "iiii d. MMMM", { locale })}
				onClick={() => datesContext.setSelectedDate(datesContext.availableDates![0])}
				icon={<SCalendarSvg />}
				inverse />
			</div>}
		</SText>
	</SNoShows>;
}
//#endregion

//#region [Styles]
const SNoShows = styled.div`
	background-color: var(--buttoncolor);
	color: var(--buttontextcolor);
	padding: 5px 12px;
	margin: ${theme.maxContentPadding};
	border-radius: 15px;
	text-align: center;
	min-height: 321px;

	@media ${theme.mq.desktop} {
		//@media (orientation: landscape) {
		margin: 15px 0px;
	}
`;

const SText = styled.div`
	font-size: 16px;
	@media ${theme.mq.desktop} {
		padding: 29px 34px 0;
	}
`;

const SDateSelectorButton = styled(Button)`
	margin-left: 1ch;
	vertical-align: middle;
`;

const SCalendarSvg = styled(CalendarSvg)`
	path {
		fill: var(--buttoncolor);
	}
`
//#endregion