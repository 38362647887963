import CheckmarkSvg from "src/components/svg/CheckMarkSvg";
import theme from "src/components/themes/DefaultTheme";
import styled, { css } from 'src/lib/styles/css';

//#region [Props]
type LabelledCheckProps = {
	className?: string;
	selected?: boolean;
	selectionCallback: (isSelected: boolean) => void;
	value: string | number;
	label?: string;
};
//#endregion

//#region [Component]
export default function LabelledCheck({ className, selected = false, selectionCallback, value, label }: LabelledCheckProps) {
	return <label className={className}>
		<SCheckInput type="checkbox" checked={selected} onChange={() => selectionCallback(!selected)} value={value} />
		<SLabelSpan $isSelected={selected}>{selected && <SCheckMarkSvg />} {label ?? value}</SLabelSpan>
	</label>;
}
//#endregion

//#region [Styles]
const SCheckInput = styled.input`
	position: absolute;
	visibility: hidden;
`;

type SLabelSpanProps = {
	$isSelected: boolean;
};

const SLabelSpan = styled.span<SLabelSpanProps>`
	background: var(--buttoncolor);
	color: var(--buttontextcolor);
	border-radius: 4px; // this is font size + 2 * vertical padding
	border: none;
	padding: 0.5em 1em;
	line-height: 1;
	font-size: 14px;
	text-align: center;
	min-height: 34px;
	display: inline-flex;
	align-items: center;
	position: relative;

	margin: 5px;

	@media ${theme.mq.desktop} {
		//@media (orientation: landscape) {
		padding: 0.5em 24px;
	}

	&:hover {
		cursor: pointer;
	}

	${props => props.$isSelected ? css`
		background: var(--buttonactivecolor, var(--buttontextcolor));
		color: var(--buttoncolor);

		@media ${theme.mq.desktop} {
			//@media  (orientation: landscape) {
			padding-left: 6px;
			padding-right: 18px;
		}
	`: ""}
`;

const SCheckMarkSvg = styled(CheckmarkSvg)`
	height: 18px;
	max-height: 18px;
	margin-right: 6px;
	fill: var(--buttoncolor);
	vertical-align: top;
	display: inline-block;

	path {
		fill: var(--buttoncolor);
	}
`;
//#endregion