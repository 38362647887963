import FilterSelector from "src/components/filterselector/FilterSelector";
import React, { useContext, useState } from "react";

//#region [Other]

export type TimeIntervalType = (date: Date) => boolean;

export type ProgramFilterStateType = {
	perspective: string[] // 2d/3d
	genres: string[];
	highlightPremieres: boolean;
	highlightKinoklubb: boolean;
	timeIntervals: TimeIntervalType[];
	selectedCinemas: string[];
	showFilterDialog: boolean;
};

type ProgramFilterContextType = {
	reset: () => void;
	applyFilter: (newFilter: ProgramFilterStateType) => void;
	setShowFilterDialog: (show: boolean) => void;
} & ProgramFilterStateType;

export const EMPTY_STATE: ProgramFilterStateType = {
	perspective: [],
	highlightPremieres: false,
	highlightKinoklubb: false,
	genres: [],
	timeIntervals: [],
	selectedCinemas: [],
	showFilterDialog: false
};

const ProgramFilterContext = React.createContext<ProgramFilterContextType>({
	...EMPTY_STATE,
	applyFilter: (newFilter) => { },
	reset: () => { },
	setShowFilterDialog: (show) => { }

});

//#endregion

//#region [Props]
type ProgramFilterWrapperProps = {
	children: React.ReactNode;
};
//#endregion

//#region [Component]
export default function ProgramFilterWrapper({ children }: ProgramFilterWrapperProps) {
	const [filterState, setFilterState] = useState<ProgramFilterStateType>(EMPTY_STATE);

	/*
	useWhatChanged(
		[filterState.genres, filterState.highlightKinoklubb, filterState.highlightPremieres, filterState.perspective, filterState.selectedCinemas, filterState.timeIntervals],
		"genres, highlightKinoklubb, highlightPremieres, perspective, selectedCinemas, timeIntervals",
		"filterState");
		*/
	return <ProgramFilterContext.Provider value={{
		...filterState,
		reset: () => setFilterState(EMPTY_STATE),
		applyFilter: (newFilter: ProgramFilterStateType) => {
			console.debug("Applying new filter", newFilter);
			setFilterState((oldState) => ({ ...oldState, ...newFilter }));
		},
		setShowFilterDialog: (show) => setFilterState((oldState) => ({ ...oldState, showFilterDialog: show }))
	}}>
		{children}
		{filterState.showFilterDialog && <FilterSelector />}
	</ProgramFilterContext.Provider>;
}
//#endregion


//#region [Other]
export function useProgramFilterContext() {
	return useContext(ProgramFilterContext);
}
//#endregion