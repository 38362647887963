import Button from "src/components/button/Button";
import FilterSvg from "src/components/svg/FilterSvg";
import { useProgramFilterContext } from "src/lib/contexts/ProgramFilterWrapper";
import { useSelectedCinemaContext } from "src/lib/contexts/SelectedCinemaContext";
import styled from 'src/lib/styles/css';

//#region [Props]
type FilterSelectorButtonProps = {
	className?: string;
};
//#endregion

//#region [Component]
export default function FilterSelectorButton({ className }: FilterSelectorButtonProps) {
	const cinemaContext = useSelectedCinemaContext();
	const filterContext = useProgramFilterContext();

	return <Button
		text="Filter"
		disabled={(cinemaContext.availableCinemas?.length ?? 0) === 0}
		icon={<FilterSvg />}
		onClick={() => filterContext.setShowFilterDialog(true)} className={className}/>
}
//#endregion

//#region [Styles]

//#endregion