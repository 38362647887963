"use client"
import { useMediaQuery } from "@react-hooks-library/core";
import Ad from "src/components/ads/Ad";
import withWatchlist from "src/components/filmwebid/withWatchlist";
import NoShows from "src/components/program/NoShows";
import ProgrammedMovie from "src/components/program/ProgrammedMovie";
import theme from "src/components/themes/DefaultTheme";
import { useLocationContext } from "src/lib/contexts/LocationContext";
import styled, { css } from 'src/lib/styles/css';
import { ProgramMovie } from "src/lib/types/filminfo/ProgramMovie";

//#region [Props]
type PosterGridProps = {
	movies?: ProgramMovie[];
};
//#endregion




//#region [Component]
export default function PosterGrid({ movies }: PosterGridProps) {
	const isDesktop = useMediaQuery(theme.mq.desktop);
	const locationContext = useLocationContext();

	if((movies?.length ?? 0) === 0 && !locationContext.location ) {
		return null;
	}

	return <SProgramContainer>
		{(movies?.length ?? 0) === 0 && <SNoShows />}

		{(movies?.length ?? 0) > 0 && <>
			{/** MOBILE */}
			{!isDesktop && <>
				<WLProgrammedMovie movie={movies![0]} wlcClassName="WLCProgrammedMovie" wlcStyle={{ order: 0 }} />

				{movies!.length > 2 &&
					<SAd targetId="FW_topp_program_v2" removeIfNoAd supportsFixedAd pageHasStickyMenu $order={0} />}

				{movies!.length > 1 && <>
					{movies!.slice(1).map((movie, index) => <WLProgrammedMovie
						key={`g2_${index}`}
						movie={movie}
						wlcStyle={{ order: index + 1 }}
						wlcClassName="WLCProgrammedMovie"
					/>)}

					{movies!.length > 8 && <SAd
						targetId="FW_midt1_program_v2"
						$order={5}
					/>}

					{movies!.length > 13 && <SAd
						targetId="FW_midt2_program_v2"
						$order={10}
					/>}

					{movies!.length > 18 && <SAd
						targetId="FW_midt3_program_v2"
						$order={15}
					/>}

				</>}

				{/** The last ad. NOTE: Keep here to avoid rendering before isDesktop is resolved*/}
				<SAd targetId="FW_bunn1_program_v2" $order={movies!.length} removeIfNoAd supportsFixedAd pageHasStickyMenu />
			</>}

			{/** DESKTOP */}
			{isDesktop && <>
				{movies!.slice(0, 4).map((movie, index) => <WLProgrammedMovie
					key={`g1_${index}`}
					movie={movie}
					wlcStyle={{ order: index }}
				/>)}

				{movies!.length > 7 && <SAd targetId="FW_topp_program_v2" $order={3} removeIfNoAd supportsFixedAd pageHasStickyMenu />}

				{movies!.length > 4 && <>

					{movies!.slice(4).map((movie, index) => <WLProgrammedMovie
						key={`g2_${index}`}
						movie={movie}
						wlcStyle={{ order: index + 4 }}
					/>)}
					{/** Only show 2nd ad if we have 24 or more movies */}
					{movies!.length > 23 &&
						<SAd targetId="FW_midt1_program_v2" $order={11} removeIfNoAd supportsFixedAd pageHasStickyMenu />}
				</>}

				{/** The last ad. NOTE: Keep here to avoid rendering before isDesktop is resolved*/}
				<SAd targetId="FW_bunn1_program_v2" $order={movies!.length} removeIfNoAd supportsFixedAd pageHasStickyMenu />
			</>}
		</>}

	</SProgramContainer>;
}
//#endregion



//#region [Styles]
const gridGap = "72px";

const SProgramContainer = styled.div`
	//min-height: calc((100vw - 20px) * ${theme.posterAspectRatio});
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	max-width: 100%;

	@media ${theme.mq.desktop} {
		display: grid;
		grid-template-columns: [start] minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) [end];
		grid-template-rows: auto;
		gap: ${gridGap};
		margin-bottom: ${gridGap};
		//min-height: calc(((100% - 4.2% * 3 - 30px) / 4 - 20px) * ${1 / theme.posterAspectRatio});
	}

	@media ${theme.mq.desktop} and (max-width: 	${theme.maxContentWidth}px) {
		gap: 2.2vw;
		margin-bottom: 2.2vw;
		//min-height: 464px;
	}

	@media ${theme.mq.desktop} and (max-width: 900px) {
		grid-template-columns: [start] minmax(0, 1fr) minmax(0, 1fr) [end];
		//min-height: calc(((100vw - 4.2vw * 1 - 30px) / 2 - 20px) * ${theme.posterAspectRatio});
	}

	@media (min-width: 900px) and (max-width: 1400px) {
		grid-template-columns: [start] minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) [end];
		//min-height: calc(((100vw - 4.2vw * 2 - 30px) / 3 - 20px) * ${theme.posterAspectRatio});
	}

	.WLCProgrammedMovie {
		width: 100%;
	}
`;

const SNoShows = styled(NoShows)`
	width: 100%;

	@media ${theme.mq.desktop} {
		grid-column-start: start;
		grid-column-end: end;
	}

`;

type SAdProps = {
	$order?: number;
}

const SAd = styled(Ad) <SAdProps>`
	flex: 0 0 100vw;
	margin-bottom: 20px;

	@media ${theme.mq.desktop} {
		grid-column: start / end;
		margin-bottom: 0;
	}

	${props => props.$order ? css`order: ${props.$order}` : ""}
`;

//#endregion

const WLProgrammedMovie = withWatchlist(ProgrammedMovie, "movie");