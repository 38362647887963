import { EMPTY_STATE, ProgramFilterStateType, type TimeIntervalType, useProgramFilterContext } from "src/lib/contexts/ProgramFilterWrapper";
import { useSelectedCinemaContext } from "src/lib/contexts/SelectedCinemaContext";
import styled from 'src/lib/styles/css';
import { useEffect, useReducer } from "react";
import SelectorWrapper from "src/components/selectorwrapper/SelectorWrapper";
import LabelledCheck from "src/components/labelledcheck/LabelledCheck";
import { getHours } from "date-fns";
import Button from "src/components/button/Button";
import theme from "src/components/themes/DefaultTheme";
//#region [Props]
type FilterSelectorProps = {
};
//#endregion


//#region [Component]
export default function FilterSelector({ }: FilterSelectorProps) {
    const cinemaContext = useSelectedCinemaContext();
    const filterContext = useProgramFilterContext();
    const [filterState, filterDispatch] = useReducer(reducer, EMPTY_STATE);

    useEffect(() => {
        filterDispatch({ type: "replace", state: { ...filterContext } });
    }, [filterContext]);


    return <SelectorWrapper onClose={() => { filterDispatch({ type: "reset" }); filterContext.setShowFilterDialog(false); }} heading="Filtrer">
        <div>
            {cinemaContext.availableCinemas.length > 1 && <SGroup>
                <SGroupTitle>Kinobygg</SGroupTitle>
                <LabelledCheck
                    value="Alle kinoer"
                    selectionCallback={isSelected => filterDispatch({ type: "cin.replace", cinemas: [] })}
                    selected={filterState.selectedCinemas.length === 0}
                />
                {cinemaContext.availableCinemas.map(cinema => <LabelledCheck
                    key={cinema.name}
                    value={cinema.name}
                    selectionCallback={isSelected => (isSelected ?
                        filterDispatch({ type: "cin.add", cinemas: [cinema.name] })
                        : filterDispatch({ type: "cin.remove", cinemas: [cinema.name] }))}
                    selected={filterState.selectedCinemas.includes(cinema.name)}

                />)}
            </SGroup>}

            <SGroup>
                <SGroupTitle>Andre</SGroupTitle>
                {VALID_PERSPECTIVES.map(p => <LabelledCheck
                    key={p}
                    value={p}
                    selected={filterState.perspective.includes(p)}
                    selectionCallback={isSelected => (isSelected ?
                        filterDispatch({ type: "per.add", perspective: p })
                        : filterDispatch({ type: "per.remove", perspective: p }))}
                />)}

                <LabelledCheck
                    value="Ukens premierer"
                    selected={filterState.highlightPremieres}
                    selectionCallback={isSelected => filterDispatch({ type: "highlight.premieres", highlight: isSelected })}
                />

                {VALID_GENRES.map(g => <LabelledCheck
                    key={g}
                    value={g}
                    selected={filterState.genres.includes(g)}
                    selectionCallback={isSelected => (isSelected ?
                        filterDispatch({ type: "genre.add", genre: g })
                        : filterDispatch({ type: "genre.remove", genre: g }))}
                />)}

                {VALID_TIME_INTERVALS.map((interval, index) => <LabelledCheck
                    key={index}
                    value={VALID_TIME_INTERVAL_LABELS[index]}
                    selected={filterState.timeIntervals.includes(interval)}
                    selectionCallback={isSelected => (isSelected ?
                        filterDispatch({ type: "int.add", interval })
                        : filterDispatch({ type: "int.remove", interval }))}
                />)}

                <SKKCheck
                    value="Kinoklubb"
                    selected={filterState.highlightKinoklubb}
                    selectionCallback={isSelected => filterDispatch({ type: "highlight.kinoklubb", highlight: isSelected })}
                />
            </SGroup>

            <SButtonGroup>
                <SApplyButton text="Vis"
                    onClick={() => {
                        filterContext.applyFilter(filterState);
                        filterContext.setShowFilterDialog(false);
                    }} isLarge />

                <SCancelButton text="Avbryt"
                    onClick={() => {
                        filterContext.setShowFilterDialog(false);
                    }}
                    isLarge
                />
            </SButtonGroup>
        </div>
    </SelectorWrapper>
}
//#endregion

const VALID_PERSPECTIVES = ["2D", "3D"];
const VALID_GENRES = ["Familiefilm"];

const VALID_TIME_INTERVALS: TimeIntervalType[] = [
    (date: Date) => {
        const h = getHours(date);
        return h >= 0 && h < 17;
    },
    (date: Date) => {
        const h = getHours(date);
        return h >= 17;
    }];
const VALID_TIME_INTERVAL_LABELS = ["Før 17:00", "Etter 17:00"];

type FilterActionType = {
    type: "per.add"
    | "per.remove"
    | "highlight.premieres"
    | "highlight.kinoklubb"
    | "genre.add"
    | "genre.remove"
    | "int.add"
    | "int.remove"
    | "cin.add"
    | "cin.remove"
    | "cin.replace"
    | "reset"
    | "replace";
}

type PerspectiveAddActionType = {
    perspective: string;
} & FilterActionType;

type HighlightActionType = {
    highlight: boolean;
} & FilterActionType;

type GenreActionType = {
    genre: string;
} & FilterActionType;

type IntervalActionType = {
    interval: TimeIntervalType;
} & FilterActionType;

type CinemaActionType = {
    cinemas: string[];
} & FilterActionType;

type ReplaceStateActionType = {
    state: ProgramFilterStateType;
} & FilterActionType;

type ActionType = PerspectiveAddActionType | HighlightActionType | GenreActionType | IntervalActionType | CinemaActionType | ReplaceStateActionType | FilterActionType;

function reducer(state: ProgramFilterStateType, action: ActionType) {
    let newState = { ...state };
    switch (action.type) {
        case "per.add":
            if (!newState.perspective.includes((action as PerspectiveAddActionType).perspective)) {
                newState.perspective = [...newState.perspective, (action as PerspectiveAddActionType).perspective];
            }
            break;
        case "per.remove":
            newState.perspective = newState.perspective.filter(d => d !== (action as PerspectiveAddActionType).perspective);
            break;
        case "highlight.premieres":
            newState.highlightPremieres = (action as HighlightActionType).highlight;
            break;
        case "highlight.kinoklubb":
            newState.highlightKinoklubb = (action as HighlightActionType).highlight;
            break;

        case "genre.add":
            if (!newState.genres.includes((action as GenreActionType).genre)) {
                newState.genres = [...newState.genres, (action as GenreActionType).genre];
            }
            break;
        case "genre.remove":
            newState.genres = newState.genres.filter(d => d !== (action as GenreActionType).genre);
            if ((action as GenreActionType).genre === "Familiefilm") {
                newState.genres = newState.genres.filter(d => d !== "Barnefilm");
            }
            break;
        case "int.add":
            if (!newState.timeIntervals.includes((action as IntervalActionType).interval)) {
                newState.timeIntervals = [...newState.timeIntervals, (action as IntervalActionType).interval];
            }
            break;
        case "int.remove":
            newState.timeIntervals = newState.timeIntervals.filter(i => i !== (action as IntervalActionType).interval);
            break;
        case "cin.add":
            // add the cinema if it does not exist
            if (newState.selectedCinemas.length === 0) {
                newState.selectedCinemas = [(action as CinemaActionType).cinemas[0]];
            } else if (!newState.selectedCinemas.includes((action as CinemaActionType).cinemas[0])) {
                newState.selectedCinemas = [...newState.selectedCinemas, (action as CinemaActionType).cinemas[0]]; // return a new object
            }
            break;
        case "cin.remove":
            newState.selectedCinemas = newState.selectedCinemas?.filter(cin => cin !== (action as CinemaActionType).cinemas[0]); // new object
            break;
        case "cin.replace":
            newState.selectedCinemas = (action as CinemaActionType).cinemas; // new object
            break;
        case "reset":
            newState = { ...EMPTY_STATE };
            break;
        case "replace":
            newState = (action as ReplaceStateActionType).state;
            break;
        default:

    }
    return newState;
}

//#region [Styles]
const SGroup = styled.div`
	padding: 0 10px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}
`;

const SGroupTitle = styled.h3`
	flex: 0 0 100%;
	margin: 0 5px;
	font-size: 1rem;
`;

const SKKCheck = styled(LabelledCheck)`
	span {
		background-color: var(--kinoklubbcolor);
		color: var(--backgroundcolor);
	}
	path {
		fill: var(--backgroundcolor);
	}
`;

const SButtonGroup = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 20px;

	gap: 10px;
`;

const SApplyButton = styled(Button)`
	background-color: var(--buttoncolor1, ${theme.buttonColor});
	color: var(--buttoncolor, ${theme.textColor});
	font-weight: bold;
`;

const SCancelButton = styled(Button)`
	background-color: transparent;
	color: var(--buttoncolor1, ${theme.buttonColor});
	border: 2px solid var(--buttoncolor1, ${theme.buttonColor});
	font-weight: bold;
`;

//#endregion
