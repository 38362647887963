import { StandardSvgProps } from "src/components/svg/StandardSvgProps";
import { SDefaultSvg, SFilledPath } from 'src/components/svg/SvgStyles';
//#region [Props]
type FilterSvgProps = {
} & StandardSvgProps;
//#endregion

//#region [Component]
export default function FilterSvg({ className }: FilterSvgProps) {
	return (
		<SDefaultSvg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.429 15">
			<g transform="translate(0 15) rotate(-90)">
				<rect width="15" height="2.143" rx="1" transform="translate(0 1.429)" />
				<rect width="15" height="2.143" rx="1" transform="translate(0 7.143)" />
				<rect width="15" height="2.143" rx="1" transform="translate(0 12.857)" />
				<rect width="5" height="2.143" rx="1" transform="translate(5 11.429) rotate(90)" />
				<rect width="5" height="2.143" rx="1" transform="translate(8.571) rotate(90)" />
				<rect width="5" height="2.143" rx="1" transform="translate(12.143 5.714) rotate(90)" />
			</g>
		</SDefaultSvg>
	);
}
//#endregion