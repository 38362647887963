import CmsImage from "src/components/images/CmsImage";
import { CmsImageForUrl } from "src/components/images/CmsImageForUrl";
import Image from "src/components/images/Image";
import theme from "src/components/themes/DefaultTheme";
import { ImageVersionType, MovieType } from "src/lib/movieinfo/movieinfotypes";
import styled, { css } from 'src/lib/styles/css';

//#region [Props]
type MoviePosterImageProps = {
	movie: MovieType;
	className?: string;
	missingPosterOffCentre?: boolean;
};
//#endregion

//#region [Component]
export default function MoviePosterImage({ movie, className, missingPosterOffCentre = false}: MoviePosterImageProps) {
	return <SPosterSizer className={className}>
		{movie.sanityImagePosterUrl &&
			<CmsImageForUrl imageUrl={movie.sanityImagePosterUrl} alt={`Plakat for ${movie.title}`}/>
		}
		{!movie.sanityImagePosterUrl && <SMissingPoster data-postermissingtext="Plakat mangler" $offCentre={missingPosterOffCentre}>
			{movie.title}
		</SMissingPoster>}
	</SPosterSizer>;
}
//#endregion

//#region [Styles]
const SPosterSizer = styled.div`
	background: ${theme.missingPoster};
	aspect-ratio: ${1 / theme.posterAspectRatio};
`;

type SMissingPosterProps = {
	$offCentre: boolean;
}

const SMissingPoster = styled.div<SMissingPosterProps>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 10px;
	aspect-ratio: ${1/theme.posterAspectRatio};
	color: var(--buttontextcolor);
	text-align: center;
	line-height: 1.5;

	${props => props.$offCentre ? css`
		justify-content: flex-start;
		padding-top: ${theme.posterAspectRatio * 30}%;
	`: ""}

	&::after {
		content: attr(data-postermissingtext);
		display: block;
		font-size: 0.8em;
		opacity: 0.8;
	}
`;

//#endregion