import styled from 'src/lib/styles/css';
import Head from 'next/head';
import { useRouter } from "next/router";

//#region [Props]
type KinoprogramSeoProps = {
	serverLocation?: string | null;
};
//#endregion

//#region [Component]
export default function KinoprogramSeo({ serverLocation }: KinoprogramSeoProps) {
	const router = useRouter();
	const query = router.query;
	let location = serverLocation;
	/* TODO: Klient må kunne overstyre location
	if (query.location) {
		if( Array.isArray(query.location)) {
			if( query.location.length > 0) {

			}
		}
		location = null;
	}*/

	const desc = "Her finner du kinoprogrammet og billettkjøp til din kino, samt informasjon om aldersgrense, sjanger, trailer og fakta om filmen.";

	const title = `${location ? `${location} - ` : ""}Kinobilletter og program - Filmweb`;

	let url = location ? "?location=" + location : "";


	return <Head>
		<meta key="ogTitle" property="og:title" content={title} />

		<meta key="ogDescrtiption" property="og:description" content={desc} />
		<meta key="metaDescription" name="description" content={desc} />
		<meta key="ogUrl" property="og:url" content={`https://www.filmweb.no${url}`} />
		<title key="title">{title}</title>
	</Head>;
}
//#endregion

//#region [Styles]

//#endregion