"use client"
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

export type SupportsScrollRestoration = {
	isReadyForScrollRestoration?: (isReady: boolean) => void;
};

export default function withScrollRestoration<P extends SupportsScrollRestoration>(WrappedComponent: React.ComponentType<P>, delay?: number) {
	const EnhancedComponent = (props: P) => {
		const router = useRouter();
		const [isChildReady, setIsChildReady] = useState(false);

		useEffect(() => {
			// set scroll restoration to manual
			if ('scrollRestoration' in history && history.scrollRestoration !== "manual") {
				console.debug("Setting scroll restoration to manual");
				const oldScrollRestoration = history.scrollRestoration;
				history.scrollRestoration = "manual";

				return () => {
					history.scrollRestoration = oldScrollRestoration;
				}
			}
		}, []);

		useEffect(() => {
			// handle and store scroll position
			const handleRouteChange = (url: string, { shallow }: { shallow: boolean }) => {
				if (!shallow) {
					console.debug("Saving scroll position", window.scrollY.toString());
					sessionStorage.setItem('scrollPosition', window.scrollY.toString());

				}
			};

			router.events.on('routeChangeStart', handleRouteChange);
			return () => {
				router.events.off('routeChangeStart', handleRouteChange);
			}

		}, [router]);


		useEffect(() => {
			let _timer: number | undefined;
			// restore scroll position
			if (isChildReady && 'scrollPosition' in sessionStorage) {
				if ((delay ?? 0) > 0) {
					_timer = window?.setTimeout(() =>{
						console.debug("Restoring deplayed scroll position", Number(sessionStorage.getItem('scrollPosition')));
						window.scrollTo(0, Number(sessionStorage.getItem('scrollPosition')));
						sessionStorage.removeItem('scrollPosition');
						_timer = undefined;
					}, delay);
				} else {
					console.debug("Restoring scroll position", Number(sessionStorage.getItem('scrollPosition')));
					window.scrollTo(0, Number(sessionStorage.getItem('scrollPosition')));
					sessionStorage.removeItem('scrollPosition');
				}
			}
			return () => {
				if( _timer ) {
					window?.clearTimeout(_timer);
				}
			}
		}, [isChildReady]);

		return <WrappedComponent {...props} isReadyForScrollRestoration={setIsChildReady} />;
	};
	EnhancedComponent.displayName = `WithScrollRestoration(${getDisplayName(WrappedComponent)})`;
	return EnhancedComponent;
}

function getDisplayName<P>(WrappedComponent: React.ComponentType<P>) {
	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
